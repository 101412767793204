import { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Layout from "../components/Layout";
import axios from "axios";
import "flatpickr/dist/flatpickr.min.css"; // Import Flatpickr styles
import flatpickr from "flatpickr";
import Lottie from "react-lottie";
import noDatafound from "../assets/images/Animation - 1721025848612.json";
import { msiApi } from "../Data/api";

const Mis = () => {
  const flatpickrRef = useRef(null);
  const [selectedDates, setSelectedDates] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 5)), // 10 days in the past
    endDate: new Date(), // today
  });
  const [staticData, setStaticData] = useState([]);
  console.log("mis== data===", staticData);

  useEffect(() => {
    flatpickr(flatpickrRef.current, {
      mode: "range",
      dateFormat: "Y-m-d",
      defaultDate: [selectedDates.startDate, selectedDates.endDate],
      onChange: (selectedDates) => {
        if (Array.isArray(selectedDates) && selectedDates.length === 2) {
          setSelectedDates({
            startDate: selectedDates[0],
            endDate: selectedDates[1],
          });
        }
      },
    });
  }, []);

  useEffect(() => {
    if (selectedDates.startDate && selectedDates.endDate) {
      fetchData(selectedDates.startDate, selectedDates.endDate);
    }
  }, [selectedDates]);

  const fetchData = async (startDate, endDate) => {
    try {
      const response = await axios.post(msiApi, {
        from: startDate.toISOString().split("T")[0],
        to: endDate.toISOString().split("T")[0],
      });
      console.log("API response:", response.data.result);

      if (Array.isArray(response.data.result)) {
        const sanitizedData = response.data.result.map((item) => {
          return Object.fromEntries(
            Object.entries(item).map(([key, value]) => [
              key,
              value === null ? 0 : value,
            ])
          );
        });
        setStaticData(sortDataByDate(sanitizedData));
      } else if (typeof response.data === "object" && response.data !== null) {
        const sanitizedData = Object.fromEntries(
          Object.entries(response.data).map(([key, value]) => [
            key,
            value === null ? 0 : value,
          ])
        );
        setStaticData(sortDataByDate([sanitizedData]));
      } else {
        console.error("Error: Expected an array or object from API");
        setStaticData([]);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const sortDataByDate = (data) => {
    return data.sort((a, b) => new Date(b.REN_DATE) - new Date(a.REN_DATE));
  };

  const formatDate = (value) => {
    if (value) {
      return new Date(value).toISOString().split("T")[0];
    }
    return value;
  };

  return (
    <Layout>
      <div className="max-w-full mx-auto m-1 py-2 border-2 border-gray-200 bg-gray-50">
        <div className="max-w-[1640px] mx-auto">
          <div className="rounded">
            <h1 className="text-2xl text-black font-extrabold text-center p-4">
              Table Data
            </h1>
            <div className="flex justify-center items-center text-center space-x-8 mt-5">
            <input
  className="w-72 px-4 py-3 text-lg text-gray-800 placeholder-gray-500 border border-gray-300 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 ease-in-out hover:border-blue-400 hover:shadow-lg"
  ref={flatpickrRef}
  placeholder="Click to Select Date"
/>

            </div>
          </div>
          <div className="flex justify-center items-center">
            {staticData.length <= 0 ? (
              <div className="flex flex-col items-center justify-center">
                <Lottie
                  options={{
                    autoplay: true,
                    animationData: noDatafound,
                  }}
                  style={{ width: "20%", height: "auto" }}
                />
                <div className="text-center font-semibold">No data Found</div>
              </div>
            ) : (
              <div className="p-4 flex justify-center w-full overflow-x-auto">
                <div className="w-[300px] md:w-[1000px]">
                  <DataTable value={staticData} paginator rows={6} className="mt-3 w-full">
                    <Column field="REN_DATE" header="REN_DATE" body={(data) => formatDate(data.REN_DATE)} />
                    <Column field="TYPE" header="TYPE" />
                    <Column field="FIRST" header="PUSH-2000" />
                    <Column field="DAILY-2000" header="DAILY-2000" />
                    <Column field="RETRY-2000" header="RETRY-2000" />
                    <Column field="RETRY2-2000" header="RETRY2-2000" />
                    <Column field="RETRY3-2000" header="RETRY3-2000" />
           
                  </DataTable>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Mis;
